import React, { useEffect, useState } from 'react';
import { browserLocalPersistence, setPersistence } from 'firebase/auth';
import { auth } from '../../config/firebase-config';
import LoadingLottie from '../../components/LoadingLottie';
import useAuthObserver from '../../hooks/useAuthObserver';

const AppInitialization: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [isPersistenceSet, setPersistenceSet] = useState(false);
  const { isUserDataLoading, isAuthLoaded } = useAuthObserver();

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        setPersistenceSet(true);
      } catch (error) {
        console.error('Error setting persistence:', error);
        setPersistenceSet(true);
      }
    };
    initializeAuth();
  }, []);

  if (!isAuthLoaded || !isPersistenceSet || isUserDataLoading) {
    return <LoadingLottie />;
  }

  return children;
};

export default AppInitialization;
