import React, { useState } from 'react';

interface TabOptionsProps {
  tabs: string[];
  defaultTab?: string;
  onTabChange: (tabId: string) => void;
}

const TabOptions: React.FC<TabOptionsProps> = ({ tabs, defaultTab, onTabChange }) => {
  const [activeTabId, setActiveTabId] = useState(defaultTab);
  const handleTabChange = (tab: string) => {
    onTabChange(tab);
    setActiveTabId(tab);
  };

  return (
    <div className="flex items-center justify-center border-b border-gray-200 mb-1">
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => handleTabChange(tab)}
          className={`px-4 py-2 -mb-px text-sm font-medium ${
            activeTabId === tab
              ? 'text-gray-800 border-b-2 border-gray-800'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabOptions;
