import React, { useEffect, useRef } from 'react';

interface Props {
  onScanQr: (data: string) => void;
  onScanPersonId: (data: string) => void;
}

const BarcodeScannerScreen: React.FC<Props> = ({ onScanQr, onScanPersonId }) => {
  const bufferRef = useRef<string>('');
  const timeoutRef = useRef<NodeJS.Timeout>();

  const processScannedData = (data: string) => {
    let dni = '';
    if (data.includes('@')) {
      const parts = data.split('@');
      if (parts.length >= 3) {
        const dniRegex = /^[0-9]{7,8}$/;
        for (const part of parts) {
          if (dniRegex.test(part)) {
            dni = part;
          }
        }
        return onScanPersonId(dni);
      }
    }

    return onScanQr(data);
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        return;
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (e.key === 'Enter') {
        if (bufferRef.current.length > 0) {
          const cleanedBuffer = bufferRef.current.replace(/Shift/g, '');
          processScannedData(cleanedBuffer);
          bufferRef.current = '';
        }
        return;
      }

      bufferRef.current += e.key;

      timeoutRef.current = setTimeout(() => {
        bufferRef.current = '';
      }, 100);
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [onScanQr, onScanPersonId]);

  return (
    <div className='h-screen w-full flex justify-center items-center'>
      <div>
        Listo para escanear
      </div>
    </div>
  );
};

export default BarcodeScannerScreen;
