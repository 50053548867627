import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { loginSuccess, useGetUserByFirebaseIdQuery } from '../store';

const useAuthObserver = () => {
  const dispatch = useDispatch();
  const [firebaseId, setFirebaseId] = useState('');
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);

  const { data: userData, isLoading: isUserDataLoading } = useGetUserByFirebaseIdQuery(
    { firebaseId },
    { skip: !firebaseId },
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setFirebaseId(user.uid);
      } else {
        setFirebaseId('');
        setIsAuthLoaded(true); // case user is not logged in
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const auth = getAuth();

    const refreshToken = async () => {
      try {
        const user = auth.currentUser;
        if (user && userData) {
          const token = await user.getIdToken(true);
          dispatch(loginSuccess({ user: userData, token }));
          setIsAuthLoaded(true); // case user is logged in
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    };

    // initial token refresh when userData becomes available
    refreshToken();

    // handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshToken();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Regular refresh interval (45 minutes)
    const handle = setInterval(refreshToken, 45 * 60 * 1000);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(handle);
    };
  }, [dispatch, userData]);

  return { isUserDataLoading, isAuthLoaded };
};

export default useAuthObserver;
