import { useEffect } from 'react';
import { HiOutlineDocumentReport, HiLink } from 'react-icons/hi';
import { FaBalanceScaleLeft } from 'react-icons/fa';
import EventMenuOption from '../components/EventMenuOption';
import authenticate from '../utils/authenticate';
import { UserRole } from '../models/user';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import LoadingLottie from '../components/LoadingLottie';
import Button from '../components/Button';
import copyLinkToClipboard from '../utils/copyLinkToClipboard';
import config from '../config/config';

interface Options {
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserRole[];
  hidden?: boolean;
}

const EventMenu: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { event, eventLoading } = useGetSelectedEvent();
  const role = useGetUserRole();

  useEffect(() => {
    if (role === 'SELLER') {
      navigate('orders', { replace: true });
    }
  }, [role, navigate]);

  const options: Options[] = [
    {
      title: 'Lista de asistentes',
      info: 'En este apartado encontrarás la acción de aceptar solicitudes de entradas, y podrás visualizar los asistentes al evento.',
      buttonLabel: 'Ver lista',
      redirectPath: 'orders',
      allowedUsers: ['EDITOR', 'ADMIN'],
    },
    {
      title: 'Validador de entradas',
      info: 'Con esta funcionalidad podrás validar las entradas con QR, o con DNI.',
      buttonLabel: 'Lector QR',
      redirectPath: 'scanner',
      allowedUsers: ['EDITOR', 'ADMIN', 'SCANNER'],
    },
    {
      title: 'Configuración de entradas',
      info: 'Aquí podras crear los diferentes tipos de entradas de tu evento, configurar su precio y cantidad de cada una.',
      buttonLabel: 'Editar entradas',
      redirectPath: 'tickets',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Configuración de cupones',
      info: 'Aquí podras crear y modificar los cupones de descuento de tu evento.',
      buttonLabel: 'Editar cupones',
      redirectPath: 'coupons',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Referidos',
      info: 'Aquí ver y configurar los referidos del evento.',
      buttonLabel: 'Ver referidos',
      redirectPath: 'referrals',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Encuestas',
      info: 'Aquí podras ver las respuestas a las preguntas de tu evento.',
      buttonLabel: 'Ver respuestas',
      redirectPath: 'polls',
      allowedUsers: ['ADMIN'],
      hidden: !event?.has_polls,
    },
    {
      title: 'Vendedores',
      info: 'Aquí podras configurar los vendedores de este evento y ver sus ventas',
      buttonLabel: 'Ver vendedores',
      redirectPath: 'sellers',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Pagos',
      info: 'Aquí podrás ver información de los pagos del evento',
      buttonLabel: 'Ver pagos',
      redirectPath: 'payments',
      allowedUsers: ['ROOT'],
    },
    {
      title: 'Configuración del evento',
      info: 'Aquí podrás editar la información del evento',
      buttonLabel: 'Editar evento',
      redirectPath: 'edit',
      allowedUsers: ['ROOT'],
    },
  ];

  if (!event || eventLoading) {
    return <LoadingLottie />;
  }

  const handleBalanceClick = () => {
    navigate('balance');
  };

  const handleReportClick = () => {
    navigate('sales-report');
  };

  return (
    <div>
      <div className="flex justify-between items-center my-3">
        <h1 className="text-2xl">{event.name}</h1>
        <div className="flex gap-2">
        {role === 'ROOT' && (
            <Button onClick={handleBalanceClick}>
              <FaBalanceScaleLeft className="text-xl" />
            </Button>
        )}
          <Button
            onClick={() => copyLinkToClipboard(`${config.baseDomain}/events/${event.id}`)
            }
          >
            <HiLink className="text-xl" />
          </Button>
          {(role === 'ADMIN' || role === 'ROOT') && (
            <Button onClick={handleReportClick}>
              <HiOutlineDocumentReport className="text-xl" />
            </Button>
          )}
        </div>
      </div>
      <hr className="mb-3" />
      {options.map(
        (option) => !option.hidden && (
            <div key={option.title}>
              {authenticate(option.allowedUsers, role) && (
                <EventMenuOption
                  id={event?.id ?? 0}
                  title={option.title}
                  info={option.info}
                  buttonLabel={option.buttonLabel}
                  redirectPath={option.redirectPath}
                />
              )}
            </div>
        ),
      )}
    </div>
  );
};

export default EventMenu;
