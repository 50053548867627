import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store';

const useGetUserRole = (options?: { skip?: boolean }) => {
  const { business_id: businessId } = useParams();
  if (options?.skip) {
    return 'USER';
  }
  if (!businessId || !+businessId) {
    throw new Error('no business_id');
  }
  const { user: userData } = useSelector(
    (state: RootState) => state.auth,
  );
  try {
    return userData.type === 'ROOT' ? 'ROOT' : userData.user_businesses.find((ub) => ub.business_id === +businessId)?.permissions || 'SCANNER';
  } catch (error) {
    console.error(error);
    console.log({ userData });
    return 'USER';
  }
};

export default useGetUserRole;
